.dialog-doctorRegister-appBar {
  background: #1e88e5 !important;
  .dialog-doctorRegister-toolBar {
    justify-content: flex-end;
  }
}
.dialog-doctorRegister-paper {
  max-width: 405px;
  margin: auto;
  padding: 20px;
}
