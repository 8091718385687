.footerModal {
  h2 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
    line-height: 1.3;
  }
  h3 {
    font-size: 14px;
    font-weight: 700;
  }
  p {
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 1.3;
  }
  ul {
    font-size: 14px;
    list-style: inside;
    margin-bottom: 10px;
  }
}
