.dialog-find-content {
  padding: 100px 15px 50px;
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  p {
    font-size: 14px;
    line-height: 1.5;
    max-width: 900px;
  }
  .dialog-find-item {
    display: flex;
    align-items: flex-start;
    margin-top: 30px;
    @media screen and (max-width: 700px) {
      flex-direction: column;
      margin-top: 50px;
    }
    img {
      max-width: 176px;
      box-shadow: 2px -2px 5px 0 rgba(0, 0, 0, 0.26);
      @media screen and (max-width: 700px) {
        margin: 0 auto;
      }
    }
    .dialog-find-paper {
      background: #fff;
      margin-left: 20px;
      width: 100%;
      padding: 20px 40px 25px 20px;
      box-shadow: 2px -2px 5px 0 rgba(0, 0, 0, 0.26);
      @media screen and (max-width: 700px) {
        margin: 10px 0 0;
      }
      h6 {
        font-weight: 700;
        margin-bottom: 20px;
      }
    }
  }
}
