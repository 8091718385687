$r: "Roboto", sans-serif;
$rs: "Roboto Slab", serif;
.loginLayout {
  font-family: "Roboto Slab", serif;
  line-height: 1.2;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  outline: none;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.3em;
  }
  button {
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    border-radius: 12px;
  }
  a {
    text-decoration: none;
    &:visited {
      color: none;
    }
  }
  .container {
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
    height: 100%;
    // max-width: 1200px;
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 250px;
    text-align: center;
    width: 100%;
    text-decoration: none;
    border-radius: 14px;
    font-weight: 700;
    color: #fff;
    transition: 0.3s;
  }
  .vbtn {
    font-family: $r;
    background: #1e88e5;
    min-height: 36px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    transition: all 0.5s;
    cursor: pointer;
    &:hover {
      border-radius: 10px;
    }
    a,
    p {
      color: #fff;
      text-transform: uppercase;
      font-size: 14px;
      width: 100%;
      text-align: center;
      line-height: 36px;
    }
  }
  .simplePaper {
    background: #fff;
    max-width: 1750px;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 0 12px 0px rgba(0, 0, 0, 0.34);
    margin: 0 auto;
    overflow: hidden;
  }
}
