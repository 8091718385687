.page5 {
  .section1 {
    background: url("../../../assets/images/p5bg.png") no-repeat center/cover;
    height: 700px;
    position: relative;
    @media screen and (max-width: 1500px) {
      height: 500px;
    }
    @media screen and (max-width: 992px) {
      height: auto;
      padding: 100px 0 50px;
    }
    .container1 {
      max-width: 1200px;
      padding: 0 15px;
      margin: 0 auto;
    }
    .section1__wrap {
      position: relative;
      padding-top: 100px;
      @media screen and (max-width: 992px) {
        padding-top: 0px;
      }
      h2 {
        font-size: 26px;
        margin-bottom: 30px;
      }
      .section1__item {
        display: flex;
        margin-bottom: 5px;
        p {
          margin-right: 10px;
        }
      }
    }
  }
  .section2 {
    padding: 50px 0;
    @media screen and (max-width: 992px) {
      padding: 20px 0;
    }
    h3 {
      font-size: 26px;
      text-align: center;
    }
    .vbtn {
      max-width: 245px;
      margin: 50px auto 0;
      box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
      p {
        text-transform: none;
      }
    }
  }
}
