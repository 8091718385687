.dialog-doctorRegister {
  padding-top: 60px;
  .dialog-doctorRegister-paper {
    max-width: 400px;
    margin: 0 auto;
  }
  .dialog-doctorRegister-confirm {
    max-width: 600px;
    margin: 0 auto;
  }
  .dialog-doctorRegister-title {
    font-size: 24px;
    text-align: center;
  }
}
