$r: "Roboto", sans-serif;
.page2 {
  background: #dcdcdc;
  .container1 {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
  .section1 {
    background: url(../../../assets/images/p2logo.jpg) no-repeat center/cover;
    height: 700px;
    position: relative;
    z-index: 10;
    .section1-title {
      padding-top: 140px;
      h1 {
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 50px;
        padding-left: 20px;
      }
      ul {
        list-style: inside decimal;
        max-width: 550px;
        li {
          font-family: $r;
          font-size: 14px;
          line-height: 1.8;
          text-align: justify;
        }
      }
      .vbtn {
        max-width: 250px;
        background: #d81b60;
        margin: 65px 0 0 50px;
      }
    }
  }
  .section2 {
    padding-bottom: 100px;
    margin-top: -30px;
    position: relative;
    z-index: 20;
    @media screen and (max-width: 992px) {
      padding-bottom: 50px;
    }
    .first {
      background: #000;
      display: flex;
      justify-content: center;
      padding-bottom: 30px;
      @media screen and (max-width: 1365px) {
        flex-wrap: wrap;
      }
      .first__item {
        &:nth-child(2) {
          margin: 0 30px;
          @media screen and (max-width: 1365px) {
            margin: 0;
          }
        }
        img {
          max-width: 370px;
          margin-bottom: 20px;
          @media screen and (max-width: 480px) {
            width: 100%;
          }
        }
        p {
          color: #fff;
          text-align: center;
          white-space: nowrap;
          margin: 0 auto;
          font-size: 26px;
          font-weight: 700;
        }
      }
    }
    .second {
      padding-top: 50px;
      font-size: 16px;
      line-height: 1.5;
      h3 {
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 50px;
      }
      p {
        font-size: 16px;
        &.second__text {
          font-size: 21px;
          font-weight: 700;
          margin: 30px 0;
          @media screen and (max-width: 600px) {
            font-size: 16px;
          }
        }
      }
      ul {
        list-style: inside disc;
        margin-top: 20px;
        &.numberMarkers {
          list-style-type: decimal;
        }
        li {
          font-family: $r;
          font-size: 16px;
          line-height: 1.5;
        }
      }
      .second__wrap {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        @media screen and (max-width: 1000px) {
          flex-direction: column;
          align-items: center;
        }
        h3 {
          margin-bottom: 100px;
          @media screen and (max-width: 1000px) {
            margin-bottom: 30px;
          }
        }
        ul {
          padding-left: 20px;
        }
        img {
          max-width: 520px;
          @media screen and (max-width: 1000px) {
            width: 100%;
            margin: 30px 0;
          }
        }
      }
    }
    .third {
      height: 500px;
      background: #464278;
      display: flex;
      position: relative;
      img {
        height: 100%;
        max-width: 900px;
        width: 100%;
        object-fit: cover;
        position: relative;
        z-index: 1;
        @media screen and (max-width: 1365px) {
          max-width: 100%;
        }
      }
      .third__wrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media screen and (max-width: 1365px) {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 10;
        }
        h2 {
          color: #fff;
          font-size: 24px;
          font-weight: 700;
          text-align: center;
          margin-bottom: 30px;
        }
        .vbtn {
          max-width: 250px;
          background: #d81b60;
          margin: 0 auto;
        }
      }
    }
    .fourth {
      padding: 30px 0;
      .second__text {
        font-size: 21px;
        font-weight: 700;
        margin: 20px 0;
        @media screen and (max-width: 600px) {
          font-size: 16px;
        }
      }
      .fourth__wrap {
        padding-left: 30px;
        @media screen and (max-width: 600px) {
          padding-left: 0;
        }
      }

      .second__protocol {
        display: flex;
        align-items: center;
        justify-content: space-around;
        @media screen and (max-width: 1000px) {
          flex-direction: column;
        }
        .vbtn {
          max-width: 270px;
        }
        img {
          &:nth-of-type(1) {
            max-width: 180px;
          }
          &:nth-of-type(2) {
            max-width: 210px;
          }
          &:nth-of-type(3) {
            max-width: 240px;
          }
        }
      }
    }
  }
}
.container-inner {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
