$r: "Roboto", sans-serif;
.page3 {
  background: #dcdcdc;
  .section1 {
    background: url(../../../assets/images/p31.png) no-repeat center/cover;
    height: 600px;
    position: relative;
    z-index: 10;
    @media screen and (max-width: 700px) {
      height: auto;
    }
    .section1-title {
      padding-top: 150px;
      @media screen and (max-width: 700px) {
        padding: 70px 0;
      }
      h1 {
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 50px;
        padding-left: 20px;
      }
      ul {
        list-style: none;
        max-width: 420px;
        li {
          font-family: $r;
          font-size: 16px;
          line-height: 1.2;
          text-align: justify;
        }
      }
    }
  }
  .section2 {
    padding-bottom: 100px;
    margin-top: -30px;
    position: relative;
    z-index: 20;
    @media screen and (max-width: 992px) {
      padding-bottom: 50px;
    }
    .smileSection {
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.3);
      padding: 50px 0;
      max-width: 950px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 24px;
      font-family: "Roboto";
      margin: 50px auto;
      position: relative;
      .btn {
        position: absolute;
        top: -25px;
        left: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 50px;
        max-width: 500px;
        width: 90%;
        transform: translate(-50%);
        background: #e91e63;
        transition: 0.3s;
        box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.3);
        &:hover {
          box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
        }
      }
    }
    .first {
      display: flex;
      padding-top: 30px;
      @media screen and (max-width: 760px) {
        flex-direction: column;
      }
      .first__wrap {
        margin-right: 100px;
        @media screen and (max-width: 760px) {
          margin-right: 0;
        }
        h2 {
          font-size: 26px;
          font-weight: 700;
          margin-bottom: 20px;
          max-width: 700px;
        }
        p {
          font-family: $r;
          font-size: 16px;
          max-width: 570px;
          line-height: 1.5;
        }
      }
      img {
        max-width: 330px;
        width: 100%;
        @media screen and (max-width: 760px) {
          display: block;
          margin: 10px auto;
        }
      }
    }
    .second {
      background: url(../../../assets/images/p35.png) no-repeat center/cover;
      height: 500px;
      position: relative;
      @media screen and (max-width: 760px) {
        height: auto;
        padding: 80px 0;
      }
      .vbtn {
        max-width: 230px;
        position: absolute;
        top: 50%;
        transform: translateX(-50%);
        left: 20%;
        background: #e91e63;
        @media screen and (max-width: 760px) {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        p {
          font-size: 14px;
        }
      }
    }
  }
}
