$r: "Roboto", sans-serif;
.page4 {
  background: #dcdcdc;
  .section1 {
    position: relative;
    z-index: 10;
    video {
      position: relative;
      width: 100%;
      height: auto;
      z-index: 1;
      @media screen and (max-width: 400px) {
        margin-top: 50px;
      }
    }
  }
  .section2 {
    margin-top: -50px;
    position: relative;
    z-index: 20;
    padding-bottom: 50px;
    h2 {
      text-align: center;
      font-size: 26px;
      font-weight: 700;
      padding: 50px 0;
      @media screen and (max-width: 500px) {
        font-size: 22px;
        padding: 20px 0;
      }
    }
    .concept__item {
      background: #ebebeb;
      &.transparent {
        background: transparent;
      }

      .concept__container {
        max-width: 1300px;
        padding: 30px 10px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 800px) {
          flex-direction: column;
        }
        &.p0 {
          padding: 0 10px;
        }
        &.reverse {
          .concept__text {
            order: 2;
            h3 {
              text-align: center;
            }
          }
          img {
            order: 1;
          }
        }
        .concept__text {
          h3 {
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 20px;
            @media screen and (max-width: 800px) {
              text-align: center;
            }
          }
          p {
            font-family: $r;
            font-size: 16px;
            @media screen and (max-width: 800px) {
              text-align: center;
            }
          }
        }
        img {
          max-width: 310px;
          width: 100%;
          @media screen and (max-width: 800px) {
            margin: 20px 0;
          }
        }
      }
    }
    .concept__video {
      video {
        width: 100%;
      }
    }
    .vbtn {
      margin: 20px auto 50px;
      background: #ff6f00;
      max-width: 245px;
      box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
    }
  }
}
