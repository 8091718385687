.dialog-ForgotPassword {
  padding-top: 60px;
  .dialog-ForgotPassword-title {
    font-size: 26px;
    text-align: center;
    padding: 30px 15px;
  }
  .dialog-ForgotPassword-paper {
    box-shadow: 2px -2px 5px 1px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 0 auto;
    max-width: 480px;
    width: 100%;
    .dialog-ForgotPassword-paper-title {
      text-align: center;
      font-size: 15px;
    }
    #ForgotPassword-form {
      display: flex;
      flex-direction: column;
      max-width: 450px;
      width: 100%;
      margin: 0 auto;
      padding-bottom: 20px;
      .ForgotPassword-form-input {
        border-color: black;
        margin-top: 8px;
      }
      .invite-btn {
        cursor: pointer;
        margin-top: 16px;
        height: 40px;
        background-color: #1e88e5;
        color: #fff;
        border: none;
        text-transform: uppercase;
      }
    }
  }
  .dialog-ForgotPassword-appBar {
    background: #1e88e5 !important;
    .dialog-ForgotPassword-toolBar {
      display: flex;
      justify-content: flex-end;
      button {
        padding: 5px;
        border-radius: 5px;
        font-size: 14px;
      }
    }
  }
}
