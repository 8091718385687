.MuiBackdrop-root {
  background: #4b4b4b !important;
}
.dialog-text {
  line-height: 1.3em;
}
.footer {
  background: #000;
  padding: 30px 20px;
  margin-top: auto;
  nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    ul {
      margin-bottom: 30px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      width: 100%;
      li {
        width: 25%;
        text-align: center;
        @media screen and (max-width: 640px) {
          width: 50%;
          margin-bottom: 20px;
        }
        button,
        a {
          text-transform: none;
          padding: 0;
          font-weight: 400;
          color: #fff;
          white-space: nowrap;
        }
      }
    }
    .socialLinks {
      display: flex;
      .socialLink {
        transition: all 0.5s;
        &:hover {
          filter: brightness(60%);
        }
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }
}
