.page1 {
  background: #dcdcdc;
  .section1 {
    position: relative;
    z-index: 10;
    video {
      position: relative;
      width: 100%;
      height: auto;
      z-index: 1;
      @media screen and (max-width: 400px) {
        margin-top: 50px;
      }
    }
    .section1-title {
      position: absolute;
      top: 25%;
      left: 10%;
      z-index: 10;
      img {
        max-width: 300px;
        width: 100%;
        @media screen and (max-width: 700px) {
          max-width: 300px;
        }
      }
      p {
        font-size: 30px;
        font-weight: 700;
        color: #fff;
        max-width: 430px;
        margin-top: 30px;
      }
    }
  }
  @mixin section_item {
    display: flex;
    justify-content: space-between;

    margin: 0 auto;
    max-width: 1300px;
    padding: 50px 0;
    @media screen and (max-width: 800px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  @mixin item {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: calc(33.3% - 40px);
    margin: 20px;
  }
  .section2 {
    padding-bottom: 100px;
    position: relative;
    z-index: 20;
    @media screen and (max-width: 992px) {
      padding-bottom: 50px;
    }
    .first {
      @include section_item;
      width: 90%;
      .first__item {
        @include item;
        img {
          max-width: 200px;
          margin-bottom: 20px;
        }
        p {
          font-size: 18px;
          font-weight: 700;
        }
      }
    }
    .second {
      background: #000;
      .second__wrap {
        @include section_item;
      }
      .second__item {
        @include item;
        &:nth-child(1) {
          p {
            max-width: 110px;
          }
        }
        img {
          max-width: 75px;
          margin-bottom: 20px;
        }
        p {
          font-size: 18px;
          font-weight: 700;
          max-width: 250px;
          text-align: center;
          color: #fff;
        }
      }
    }
    .third {
      background: url(../../../assets/images/s41.png) no-repeat center/cover;
      min-height: 600px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 100px;
      @media screen and (max-width: 600px) {
        padding: 0 15px;
        min-height: 300px;
      }
      h2 {
        font-size: 30px;
        font-weight: 700;
        max-width: 350px;
        margin-bottom: 30px;
      }
      .third__vbtn {
        max-width: 280px;
      }
    }
    .fourth {
      display: flex;
      justify-content: center;
      padding: 100px 0;
      @media screen and (max-width: 1015px) {
        flex-wrap: wrap;
        align-items: center;
      }
      .fourth__wrap {
        max-width: 270px;
        &:nth-child(2) {
          margin: 0 50px;
          @media screen and (max-width: 1015px) {
            margin: 30px 0;
          }
        }
        &:hover {
          img {
            transform: translateY(-20px);
          }
        }
        .fourth__item {
          position: relative;

          img {
            max-width: 270px;
            min-height: 295px;
            transition: 0.5s;
          }
          .fourth__vbtn {
            max-width: 220px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -18px;
            &_pink {
              background: #e91e63;
            }
            &_violet {
              background: #673ab7;
            }
          }
        }
      }
    }
  }
}
