.dialog-protocol-content {
  padding: 100px 15px 50px;
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  .simplePaper {
    background: #fff;
    max-width: 1680px;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 0 12px 0px rgba(0, 0, 0, 0.34);
    margin: 0 auto;
    overflow: hidden;
    padding: 20px;
  }
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  h3 {
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    margin: 20px 0;
  }
  p {
    font-size: 14px;
    line-height: 1.5;
  }
  ul {
    list-style: inside decimal;
    li {
      margin-bottom: 5px;
    }
  }
  .protocol1 {
    display: block;
    margin: 0 auto 50px;
    max-width: 800px;
    width: 100%;
  }
  .protocol__wrap1 {
    display: flex;
    align-items: center;
    margin-top: 50px;
    @media screen and (max-width: 730px) {
      flex-direction: column;
      margin-bottom: 30px;
    }
    img {
      max-width: 380px;
    }
  }
  .protocol__wrap2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0 50px;
    @media screen and (max-width: 700px) {
      padding: 0;
    }
    .protocol__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
      margin-bottom: 50px;
      @media screen and (max-width: 540px) {
        width: 100%;
        margin-bottom: 30px;
      }
      img {
        display: block;
        max-width: 200px;
        margin-bottom: 20px;
        @media screen and (max-width: 540px) {
          margin-bottom: 0;
        }
      }
      p {
        text-align: center;
        max-width: 200px;
      }
    }
  }
}
