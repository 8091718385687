$r: "Roboto", sans-serif;
$rs: "Roboto Slab", serif;
.navWrap {
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(255, 255, 255, 0.75);
  width: 100%;
  .nav {
    display: flex;
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    @media screen and (max-width: 992px) {
      justify-content: space-between;
      align-items: center;
    }
    .logo {
      padding: 5px 0;
      img {
        max-width: 180px;
      }
    }
    .nav-open {
      width: 20px;
      height: 20px;
      background: none;
      display: none;
      position: relative;

      span {
        display: block;
        width: 100%;
        height: 2px;
        background: #000;
        margin-bottom: 5px;
      }
      @media screen and (max-width: 992px) {
        display: block;
      }
    }
    .nav-close {
      width: 20px;
      height: 20px;
      background: none;
      position: absolute;
      top: 10px;
      right: 10px;
      border: none;
      display: none;
      @media screen and (max-width: 992px) {
        display: block;
      }
      span {
        display: block;
        width: 100%;
        height: 2px;
        background: #000;
        &:nth-child(1) {
          transform: rotate(45deg) translate(2px, 1px);
        }
        &:nth-child(2) {
          transform: rotate(-45deg);
        }
      }
    }
    ul {
      width: 100%;
      margin-left: 50px;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 992px) {
        position: absolute;
        top: 0;
        left: 0;
        background: #fff;
        flex-direction: column;
        margin-left: 0;
        z-index: 999;
        box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.2);
        transform: translateX(-120%);
        transition: 0.5s;
        &.active {
          transform: translateX(0);
        }
      }
      li {
        height: 100%;
        display: flex;
        align-items: center;
        @media screen and (max-width: 992px) {
          justify-content: center;
        }
        a {
          font-family: $r;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 700;
          color: #000;
          white-space: nowrap;
          height: 100%;
          position: relative;
          @media screen and (max-width: 992px) {
            line-height: 3em;
          }
          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 5px;
            background: #00acc1;
            border-radius: 2px;
            opacity: 0;
            transition: 0.5s;
            @media screen and (max-width: 992px) {
              bottom: 7px;
              height: 3px;
            }
          }
          &.active {
            &::before {
              opacity: 1;
            }
          }
        }
        button {
          background: none;
          text-transform: none;
        }
      }
    }
  }
}
