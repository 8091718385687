.dialog-online {
  padding: 64px 0 0;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 600px) {
    padding-top: 56px;
  }
  .dialog-online-content {
    max-width: 710px;
    width: 100%;
    padding: 0 10px;
    margin: auto;
    .dialog-online-form {
      padding: 30px;
      border-radius: 5px;
      box-shadow: 0 0 12px 0px rgba(0, 0, 0, 0.34);
      margin-top: 30px;
      .dialog-online-wrap {
        display: flex;
        align-items: flex-end;
        .dialog-online-textarea {
          height: 100%;
          width: 95%;
        }
        .dialog-online-icon {
          height: 20px;
          background: url("../../../assets/images/editIcon.png") no-repeat center 90% / contain;
          width: 5%;
          margin-bottom: 10px;
        }
      }
      .dialog-online-email {
        max-width: 250px;
      }
      .dialog-online-send {
        display: block;
        margin-left: auto;
        color: #1976d2;
      }
    }
  }
}
