.confirm {
  padding: 100px;
}
table {
  width: 100%;
}

#mainContent {
  width: 100%;
}
#mainContent .container {
  display: block;
  margin: 0 auto;
  max-width: 800px;
  padding: 10px;
  width: 100%;
}
#mainContent .container .content {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  margin: 0 auto;
  max-width: 700px;
  width: 100%;
  padding: 10px;
}
#mainContent .container .content .main {
  background: #ffffff;
  width: 100%;
}
#mainContent .container .content .main .wrapper {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px 50px;
  .fw700 {
    font-size: 16px;
    text-align: center;
    font-weight: bold;
  }
}
#mainContent .container .content .main .wrapper.wrapper-password {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 70px 0;
}

.btn-container {
  display: block;
  width: 100%;
  float: left;
  margin: 35px 0;
}

.btn {
  display: block;
  margin: 10px auto 20px;
  max-width: 250px;
  text-align: center;
  width: 100%;
  padding: 15px 20px;
  text-decoration: none;
  border: 2px solid transparent;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  font-weight: bold;
  color: #333;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.btn.btn-accept {
  background-color: #fff;
  border-color: #e91e63;
  color: #e91e63;
  max-width: 250px;
  width: 100%;
  min-height: 34px;
  padding: 0;
  line-height: 34px;
}
.btn.btn-accept:hover {
  color: #fff;
  background: #e91e63;
}
.btn.btn-primary {
  background-color: #fff;
  border-color: #1e88e5;
  color: #1e88e5;
  max-width: 250px;
  width: 100%;
  min-height: 34px;
  padding: 0;
  line-height: 34px;
}
.btn.btn-primary:hover {
  color: #fff;
  background: #1e88e5;
}
.logoBox {
  background: #1e88e5;
  padding: 40px 0;
}
.logoBox-img {
  max-width: 220px;
  display: block;
  margin: 0 auto;
}
.recPassword {
  font-size: 26px;
}
