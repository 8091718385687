.signInForm {
  .signInForm-btn {
    text-transform: none;
    background: #1e88e5;
    border-radius: 20px;
    margin: 0 auto;
    display: flex;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.3);
    margin-top: 20px;
    &:hover {
      background: #1e88e5;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
    }
  }
  .signInForm-btn-forgot {
    padding: 15px;
    cursor: pointer;
    color: #006ce5;
    text-decoration: underline;
  }
}
