.signUpForm {
  padding-bottom: 20px;
  .pass-error {
    color: rgb(255, 0, 0);
    margin-top: 5px;
    // margin-bottom: -10px;
    text-align: center;
  }
  .signUp-terms {
    display: flex;
    align-items: center;
    margin: 0 0 10px 0;
    .signUp-terms-btn {
      padding: 0;
      text-transform: none;
      text-decoration: underline;
      color: #3700ff;
      &:hover {
        background: transparent;
      }
    }
  }
  .signUp-btn {
    background: #f57c00;
    width: 100%;
    color: #fff;
    margin: 0;
    text-transform: uppercase;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    box-shadow: 0 7px 8px 0px rgba(0, 0, 0, 0.3);
    transition: all 0.5s;
    &.disabled {
      cursor: not-allowed !important;
    }
    &:hover {
      background: #f57c00;
      box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0);
    }
  }
}
